import React, { useState, useEffect } from 'react';
import { collection, getDocs } from "firebase/firestore";
import { db } from "./firebase";


const gridStyle = {
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: 'repeat(30, 1fr)',
  gridTemplateRows: 'repeat(30, 1fr)',
  width: '800px',
  height: '800px',
};

const gridOuterStyle = { 
  backgroundColor: '#757575',
  width: '820px',
  height: '820px',
  display: "flex",
  justifyContent:"center",
  alignItems:'center',
  padding: '10px',
}
// const trapezoidStyle = {
//   position: 'absolute',
//   top: 0,
//   left: 0,
//   right: 0,
//   bottom: 0,
//   zIndex: -1,
// };

const cellStyle = {
  aspectRatio: "1 / 1",
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#BFBFBF',
  position: 'relative',
  overflow: 'hidden',
};

const trapezoidTopStyle = {
  position: 'absolute',
  top: '-7%',
  left: 0,
  right: 0,
  height: '20%',
  clipPath: 'polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%)',
  backgroundColor: '#FFFFFF',
};

const trapezoidBottomStyle = {
  position: 'absolute',
  bottom: '-7%',
  left: 0,
  right: 0,
  height: '20%',
  clipPath: 'polygon(20% 100%, 80% 100%, 100% 0%, 0% 0%)',
  backgroundColor: '#757575',
};

const trapezoidLeftStyle = {
  position: 'absolute',
  top: 0,
  left: '-7%',
  width: '20%',
  height: '100%',
  clipPath: 'polygon(0% 20%, 0% 80%, 100% 100%, 100% 0%)',
  backgroundColor: '#FFFFFF',
};

const trapezoidRightStyle = {
  position: 'absolute',
  top: 0,
  right: '-5%',
  width: '14%',
  height: '100%',
  clipPath: 'polygon(100% 20%, 100% 80%, 0% 100%, 0% 0%)',
  backgroundColor: '#757575',
};

const useResponsiveGridStyle = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile
    ? {
        ...gridStyle,
        width: '90vw',
        height: '90vw',
      }
    : gridStyle;
};

const useResponsiveGridOuterStyle = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile
    ? {
        ...gridOuterStyle,
        width: '93vw',
        height: '93vw',
      }
    : gridOuterStyle;
};

const useResponsiveTextStyle = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile
    ? {
        fontSize: '8px',
        color: '#222222',
      }
    : {
        fontSize: '14px',
        color: '#222222',
      };
};

const Grid = () => {
  const [gridData, setGridData] = useState({});
  const [marker, setMarker] = useState({ visible: false, x: 0, y: 0, text: '', link: '' });
  const gridStyle = useResponsiveGridStyle();
  const textStyle = useResponsiveTextStyle();
  const gridOuterStyle = useResponsiveGridOuterStyle();

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "gridCells"));
      const data = {};

      querySnapshot.docs.forEach((doc) => {
        const cellKey = doc.id;
        data[cellKey] = doc.data();
      });

      setGridData(data);
    };

    fetchData();
  }, []);

  const gridCells = Array.from({ length: 900 }, (_, index) => {
    const rowIndex = Math.floor(index / 30) + 1;
    const colIndex = (index % 30) + 1;
    const cellKey = `${colIndex}-${rowIndex}`;
    const cellData = gridData[cellKey] || {};
    const imageUrl = cellData.image || "";
    const color = cellData.color ; // 기본 배경색 설정
    const text = cellData.text ; // 텍스트 필드

    return (
      <div
        key={index}
        style={{
          ...cellStyle,
          backgroundImage: imageUrl ? `url(/img/grid/${imageUrl}.png)` : undefined,
          backgroundColor: imageUrl ? undefined : color, // 이미지가 없으면 배경색 적용
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          cursor: cellData.link ? 'pointer' : 'default',
        }}
        onClick={(e) => {
          const rect = e.target.getBoundingClientRect();
          const isRightAligned = colIndex < 18;
          setMarker({
            visible: true,
            x: isRightAligned ? rect.left + rect.width / 2 : rect.left - rect.width*4,
            y: rect.top - 10,
            text: (
              <>
                <div>좌표 : {cellKey}</div>
                <div>{cellData.description || '칸 정보 없음'}</div>
                <div>{cellData.link || '링크 없음'}</div>
                <div>현재가격 :{cellData.price ? ` ${cellData.price}₩` : '1000₩'}</div>
              </>
            ),
            link: cellData.link || '',
          });
        }}
      >
        {!imageUrl && !color && !text && (
          <>
            <div style={trapezoidTopStyle}></div>
            <div style={trapezoidBottomStyle}></div>
            <div style={trapezoidLeftStyle}></div>
            <div style={trapezoidRightStyle}></div>
          </>
        )}
        <span style={{ ...textStyle, color: text ? "#000000" : textStyle.color }}>
          {text}
        </span>
      </div>
    );
  });

  const markerStyle = {
    position: 'fixed',
    left: marker.x,
    top: marker.y,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
    padding: '5px 10px',
    borderRadius: '5px',
    fontSize: '12px',
    visibility: marker.visible ? 'visible' : 'hidden',
    transform: 'translate(20%, 30%)',
    zIndex: 9999,
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  };
  
  const closeButtonStyle = {
    position: 'absolute',
    right: '5px',
    top: '2px',
    color: 'white',
    width: '20px',
    height: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    cursor: 'pointer',
    fontWeight: 'bold',
  };

  const handleMarkerClick = () => {
    if (marker.link) {
      window.open(marker.link, '_blank');
    }
  };

  return (
    <div style={gridOuterStyle}>
      <div style={{ ...gridStyle, backgroundColor: '#c0c0c0' }}>
        {gridCells}
      </div>
      <div style={markerStyle} onClick={handleMarkerClick}>
        {marker.text}
        <button
          style={closeButtonStyle}
          onClick={(e) => {
            e.stopPropagation(); // Prevent triggering marker link navigation
            setMarker({ visible: false, x: 0, y: 0, text: '', link: '' });
          }}
        >
          ×
        </button>
      </div>
    </div>
  );
}

export default Grid;