import { useState, useEffect } from "react";

const useResponsiveFontSize = () => {
  const [fontSize, setFontSize] = useState(window.innerWidth <= 768 ? '8px' : '15px');

  useEffect(() => {
    const handleResize = () => {
      setFontSize(window.innerWidth <= 768 ? '8px' : '15px');
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return fontSize;
};

export default function Footer() {
  const fontSize = useResponsiveFontSize();

  return (
    <>
      <p style={{ fontSize }}>
        All rights reserved. I am not responsible for the content of external sites.
      </p>
    </>
  );
}
