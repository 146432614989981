import Banner from "./components/ui/Banner";
import Footer from "./components/ui/Footer";
import Grid from "./pixel_main";

export default function Main() {
  const mainStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // 가로 중앙 정렬
    height: '100vh', // 화면 전체 높이
    width: '100vw', // 화면 전체 너비
    
    
  };

  return (
    <div style={mainStyle}>
      <Banner />
      <Grid />
      <Footer />
    </div>
  );
}
