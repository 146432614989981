// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore,collection, doc, setDoc } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDHd9F64UHhYN2e-NttJou6N6cHsd7CYvs",
  authDomain: "cell-feba2.firebaseapp.com",
  projectId: "cell-feba2",
  storageBucket: "cell-feba2.firebasestorage.app",
  messagingSenderId: "130156031857",
  appId: "1:130156031857:web:f5dea2b1227a7590dad0d0",
  measurementId: "G-B0KRXW0T12"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);



// Firestore 및 Storage 초기화
export const db = getFirestore(app);
export const storage = getStorage(app);


// 데이터 초기화코드 ! 건들지말것
// const addGridData = async () => {
//   const batchSize = 30; // 그리드의 열/행 크기
//   const collectionRef = collection(db, 'gridCells');

//   for (let row = 1; row <= batchSize; row++) {
//     for (let col = 1; col <= batchSize; col++) {
//       const cellKey = `${col}-${row}`;
//       const cellData = {
//         row,
//         col,
//         description: '', // 필요에 따라 텍스트 추가
//         color: '', // 기본 색상
//         image: '', // 이미지 경로
//         link: '', // 링크
//         price:1000,
//       };

//       try {
//         await setDoc(doc(collectionRef, cellKey), cellData);
//         console.log(`Document ${cellKey} successfully written.`);
//       } catch (error) {
//         console.error(`Error writing document ${cellKey}:`, error);
//       }
//     }
//   }
// };

// addGridData().then(() => console.log('Grid data population complete.'));