import { useState, useEffect } from "react";

const BannerStyle = {
  width: '820px',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#B8B8B8',
  position: 'relative',
  overflow: 'hidden',
  padding: '20px 20px',
  margin: '5px 0 5px 0' // To ensure trapezoids don't overflow outside the rectangle
};


const useResponsiveBannerStyle = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile
    ? {
        ...BannerStyle,
        width: '93vw',
      }
    : BannerStyle;
};

const useResponsiveTrapezoidStyle = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    leftWidth: isMobile ? '2.3%' : '1.2%', 
    rightWidth: isMobile ? '2.3%' : '1.2%',
  };
};


export default function Banner() {
  
  const BannerStyle = useResponsiveBannerStyle();
  const { leftWidth, rightWidth } = useResponsiveTrapezoidStyle();

  const trapezoidBottomStyle = {
    position: 'absolute',
    bottom: '0',
    left: 0,
    right: 0,
    height: '10%',
    clipPath: 'polygon(1.3% 0%, 98.7% 0%, 100% 100%, 0% 100%)',
    backgroundColor: '#FFFFFF',
  };

  const trapezoidTopStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '10%',
    clipPath: 'polygon(1.3% 100%, 98.7% 100%, 100% 0%, 0% 0%)',
    backgroundColor: '#757575',
  };

  const trapezoidRightStyle = {
    position: 'absolute',
    top: 0,
    right: 0,
    width: rightWidth,
    height: '100%',
    clipPath: 'polygon(0% 10%, 100% 0%, 100% 100%, 0% 92%)',
    backgroundColor: '#FFFFFF',
  };

  const trapezoidLeftStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: leftWidth,
    height: '100%',
    clipPath: 'polygon(0% 0%, 100% 8%, 100% 90%, 0% 100%)',
    backgroundColor: '#757575',
  };

  return (
    <div style={{ margin: '3px 0 10px 0' }}>
      <header style={BannerStyle}>
        <div style={trapezoidTopStyle}></div>
        <div style={trapezoidBottomStyle}></div>
        <div style={trapezoidLeftStyle}></div>
        <div style={trapezoidRightStyle}></div>

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: "15px 0 0 0" }}>
          <h3 style={{ fontSize: '25px', fontWeight: 'bold' }}>CELL AD</h3>
          <button onClick={() => window.open('https://tree-howler-6fd.notion.site/CELL-AD-1599fa6b325580199b24d1ec79b1aa5d', '_blank')}>
            정보
          </button>
        </div>

        <div style={{display: 'flex', gap: '20px', margin: '0 auto', fontSize: '12px', padding:'15px 0 0 0'}}>
          <button>픽셀 구매하기</button>
          <p>|</p>
          <button>FAQ</button>
          <p>|</p>
          <button>Contact Me</button>
        </div>
      </header>
    </div>
  );
}
